import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <meta charSet="UTF-8"/>
          <title>ZunigaNoel</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.5"/>
        <link type="text/css" href="styles.css"/>
      </header>

      <div>
        <div id="navbar" className="topnav">
          <a className='active' id='projectsButton' href="#projects"> Current Project </a>
          <a id='devButton' href="#devlog"> Dev Logs </a>
          <a href="./images/Enrique-Zuniga-Resume.pdf" target="_blank" rel="noopener noreferrer"> Resumé </a>
          <a href="https://github.com/zuniganoel" target="_blank" rel="noopener noreferrer"> GitHub </a>
        </div>

        <div id="projects" className="section-headers">Current Project</div>
        <div id="devlog" className="section-headers">Dev Logs</div>
      </div>

      <footer>
        <div className='contact'> 
          <h2>Contact</h2>
          <dt>Enrique Zuniga</dt>
          <dt>phone number</dt>
          <dt>
            <a href="#devlog">
              email@email.com
            </a>
          </dt>
        </div>

        <div className='menu'>
          <h2>Menu</h2>
          <dt>
            <a href="#devlog"> Current Project </a>
          </dt>
          <dt>
            <a href="#devlog"> Dev Logs </a>
          </dt>
          <dt>
            <a href="./images/Enrique-Zuniga-Resume.pdf" target="_blank" rel="noopener noreferrer"> Resumé </a>
          </dt>
          <dt>
            <a href="https://github.com/zuniganoel" target="_blank" rel="noopener noreferrer"> GitHub </a>
          </dt>
        </div>

        <div className='recent'>
          <h2>Recent Posts</h2>
          <dt>
            <a href="#devlog"> The Whole Website! </a>
          </dt>
        </div>

        <div className='newsletter'>
          <h2>Newsletter</h2>
          <dt>
            <input className='input' placeholder="Your Email Address" type="email" id="email" name="email"/>
            <button className='button' type="button">Sign up</button>
          </dt>
        </div>
      </footer>
    </div>
  );
}

export default App;
